.resume-section {
  position: absolute;
  top: calc(100% + 500px);
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  width: 80%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}
  
.resume-section h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.resume-section p {
    font-size: 16px;
    line-height: 1.6;
}

.resume-section a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}