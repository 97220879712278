.about-me-section {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  transform: translate(-50%);
  /* left: 10000%; */
  text-align: center;
  width: 900px;
  /* max-width: 1000px; */
  /* min-width: 800px; */
  overflow: auto; /* Add a scrollbar when the content overflows */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.about-me-section h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-me-section p {
  font-size: 20px;
  line-height: 1.6;
}

