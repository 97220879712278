
.main-container {
  max-width: vw;
  margin: 0 auto;
  position: relative;
}

.profile img {
  position: relative;
  width: 100vw;
  height: 0;
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
  overflow: hidden;
  /* margin-bottom: 20px; */
}
.img-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.img-content {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Hi {
  position: absolute;
  top: 25%;
  left: 20%;
  color: white;
  font-size: 4vw;
  font-family: 'Playfair Display', italic;
  font-style: italic;
  text-align: center;
}
.Albert {
  position: absolute;
  top: 27%;
  left: 10%;
  color: rgb(229, 225, 239);
  font-size: 7vw;
  font-family: 'Playfair Display', serif;
  font-weight: 500;
  font-style: bold;
  text-align: center;
  line-height: 1.6;
}